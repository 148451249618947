.product-list {
  margin: 15px 0;
  list-style: none;

  .product-list-item {
    padding: 10px;
    margin: 15px auto;
    border: 1px solid #dfdfdf;
    border-radius: 10px;

    .product-img {
      max-width: 100px;
    }

    .product-link {
      display: block;

      &:hover, &:focus, &:visited {
        text-decoration: none;
      }

      * {
        display: inline-block;
      }

      .product-title {
        margin: 0 30px;
        padding: 0 30px;
        border-left: 1px solid #cccccc;
        border-right: 1px solid #cccccc;
      }
    }
  }
}

.product {
  .product-header {
    .product-price {
      font-size: .5em;
      font-style: italic;
    }
  }

  .product-img {
    margin: 25px auto;
    max-width: 300px;
  }
}
