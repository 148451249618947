body {
  font-family: 'Syne Mono', monospace;

  .page-content {
    font-family: 'Syne Mono', monospace;
  }

  .wrapper {
    max-width: 1000px;
  }

  .navbar-brand {
    &:hover, &:visited, &:active {
      text-decoration: none;
    }
  }

  .svg-icon {
    width: 25px;
    height: 25px;
  }
}
